:root {
  --rnf-avatar-bg-color: #ef8476;
  --rnf-avatar-size: 32px;
  --rnf-avatar-initials-font-size: var(--rnf-font-size-md);
  --rnf-avatar-initials-line-height: var(--rnf-font-size-lg);
  --rnf-avatar-initials-color: #fff;
  --rnf-notification-cell-border-bottom-color: #e4e8ee;
  --rnf-notification-cell-padding: var(--rnf-spacing-3);
  --rnf-notification-cell-active-bg-color: #f1f6fc;
  --rnf-notification-cell-unread-dot-size: 6px;
  --rnf-notification-cell-unread-dot-bg-color: #80c7f5;
  --rnf-notification-cell-unread-dot-border-color: #3192e3;
  --rnf-notification-cell-content-color: var(--rnf-color-gray-900);
  --rnf-notification-cell-content-font-size: var(--rnf-font-size-md);
  --rnf-notification-cell-content-line-height: var(--rnf-font-size-lg);
  --rnf-archive-notification-btn-bg-color: var(--rnf-color-gray-400);
  --rnf-archive-notification-btn-bg-color-active: var(--rnf-color-gray-500);
}

/* Avatar */

.rnf-avatar {
  background-color: var(--rnf-avatar-bg-color);
  border-radius: var(--rnf-avatar-size);
  width: var(--rnf-avatar-size);
  height: var(--rnf-avatar-size);
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.rnf-avatar__initials {
  font-size: var(--rnf-avatar-initials-font-size);
  line-height: var(--rnf-avatar-initials-line-height);
  color: var(--rnf-avatar-initials-color);
}

.rnf-avatar__image {
  object-fit: cover;
  width: var(--rnf-avatar-size);
  height: var(--rnf-avatar-size);
}

/* Notification cell */

.rnf-notification-cell {
  background-color: transparent;
  position: relative;
  border-bottom: 1px solid var(--rnf-notification-cell-border-bottom-color);
}

.rnf-notification-cell:last-child {
  border-bottom-color: transparent;
}

.rnf-notification-cell:hover,
.rnf-notification-cell:focus,
.rnf-notification-cell:active {
  background-color: var(--rnf-notification-cell-active-bg-color);
  outline: none;
}

.rnf-notification-cell__inner {
  border: none;
  appearance: none;
  margin: 0;
  width: 100%;
  text-decoration: none;
  display: flex;
  padding: var(--rnf-notification-cell-padding);
  cursor: pointer;
  text-align: left;
  justify-content: flex-start;
}

.rnf-notification-cell__unread-dot {
  position: absolute;
  top: var(--rnf-notification-cell-unread-dot-size);
  left: var(--rnf-notification-cell-unread-dot-size);
  width: var(--rnf-notification-cell-unread-dot-size);
  height: var(--rnf-notification-cell-unread-dot-size);
  border-radius: var(--rnf-notification-cell-unread-dot-size);
  background-color: var(--rnf-notification-cell-unread-dot-bg-color);
  border: 1px solid var(--rnf-notification-cell-unread-dot-border-color);
}

.rnf-notification-cell__content-outer {
  margin-left: var(--rnf-spacing-3);
}

.rnf-notification-cell__content {
  color: var(--rnf-notification-cell-content-color);
  display: block;
  font-weight: var(--rnf-font-weight-normal);
  font-size: 18px;
  line-height: var(--rnf-notification-cell-content-line-height);
  margin-bottom: var(--rnf-spacing-1);
  word-break: normal;
  word-wrap: break-word;
}

.rnf-notification-cell__content h1,
.rnf-notification-cell__content h2,
.rnf-notification-cell__content h3,
.rnf-notification-cell__content h4 {
  font-weight: var(--rnf-font-weight-semibold);
  margin-bottom: 0.5em;
}

.rnf-notification-cell__content h1 {
  font-size: var(--rnf-font-size-2xl);
}

.rnf-notification-cell__content h2 {
  font-size: var(--rnf-font-size-xl);
}

.rnf-notification-cell__content h3 {
  font-size: var(--rnf-font-size-lg);
}

.rnf-notification-cell__content h4 {
  font-size: var(--rnf-font-size-md);
}

.rnf-notification-cell__content p {
  margin: 0 0 0.75em 0;
}

.rnf-notification-cell__content p:last-child {
  margin-bottom: 0;
}

.rnf-notification-cell__content blockquote {
  border-left: 3px solid var(--rnf-color-gray-300);
  padding-left: var(--rnf-spacing-3);
  line-height: var(--rnf-font-size-xl);
  margin: 0;
}

.rnf-notification-cell__content strong {
  font-weight: var(--rnf-font-weight-semibold);
}

.rnf-notification-cell__timestamp {
  display: block;
  color: var(--rnf-color-gray-300);
  font-size: 14px;
  font-weight: var(--rnf-font-weight-normal);
  line-height: var(--rnf-font-size-lg);
}

.rnf-notification-cell__child-content {
  margin: 0.75em 0 0.5em 0;
}

/* Archive button */

.rnf-archive-notification-btn {
  background-color: transparent;
  appearance: none;
  user-select: none;
  border: none;
  opacity: 0;
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-left: auto;
  color: var(--rnf-archive-notification-btn-bg-color);
  padding: var(--rnf-spacing-1) var(--rnf-spacing-2);
  transition: color 0.1s ease-in-out, opacity 0.2s ease-in-out;
}

.rnf-notification-cell:focus .rnf-archive-notification-btn,
.rnf-notification-cell:hover .rnf-archive-notification-btn,
.rnf-notification-cell:active .rnf-archive-notification-btn {
  opacity: 1;
}

.rnf-archive-notification-btn:focus,
.rnf-archive-notification-btn:hover,
.rnf-archive-notification-btn:active {
  outline: none;
  opacity: 1;
  color: var(--rnf-archive-notification-btn-bg-color-active);
}

/* Tooltip */

.rnf-tooltip {
  background-color: var(--rnf-color-gray-700);
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
  color: #fff;
  display: flex;
  flex-direction: column;
  padding: var(--rnf-spacing-1) var(--rnf-spacing-2);
  font-size: var(--rnf-font-size-xs);
  line-height: var(--rnf-font-size-s);
  font-weight: var(--rnf-font-weight-medium);
  transition: opacity 0.3s;
  z-index: 9999;
}

/* Themes */

.rnf-notification-cell--dark {
  --rnf-notification-cell-border-bottom-color: rgba(105, 115, 134, 0.65);
  --rnf-notification-cell-active-bg-color: #393b40;
  --rnf-notification-cell-content-color: var(--rnf-color-white-a-75);
}

.rnf-notification-cell--dark:last-child {
  border-bottom-color: transparent;
}

.rnf-notification-cell--dark .rnf-notification-cell__timestamp {
  color: var(--rnf-color-gray-500);
}

.rnf-archive-notification-btn--dark {
  --rnf-archive-notification-btn-bg-color: var(--rnf-color-gray-500);
  --rnf-archive-notification-btn-bg-color-active: var(--rnf-color-gray-400);
}

.rnf-tooltip--dark {
  background-color: #565a61;
}

@media screen and (hover: none) {
  .rnf-archive-notification-btn {
    opacity: 1;
  }
}