.apexcharts-tooltip {
  background: #F9F9FD !important;
  opacity: 1 !important;
  color: #000000 !important;
  padding: 0px !important;
}

.apexcharts-tooltip-text-y-value {
margin: 0px !important;
}

.apexcharts-xcrosshairs {
fill: #e9e9e9;
}