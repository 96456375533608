:root {
  --rnf-notification-icon-button-size: 32px;
  --rnf-notification-icon-button-bg-color: transparent;
  --rnf-notification-feed-header-height: 45px;
    /* Font sizes */
    --rnf-font-size-xs: 0.75rem;
    --rnf-font-size-sm: 0.875rem;
    --rnf-font-size-md: 1rem;
    --rnf-font-size-lg: 1.125rem;
    --rnf-font-size-xl: 1.266rem;
    --rnf-font-size-2xl: 1.5rem;
    --rnf-font-size-3xl: 1.75rem;
  
    /* Spacing */
    --rnf-spacing-0: 0;
    --rnf-spacing-1: 4px;
    --rnf-spacing-2: 8px;
    --rnf-spacing-3: 12px;
    --rnf-spacing-4: 16px;
    --rnf-spacing-5: 20px;
    --rnf-spacing-6: 24px;
    --rnf-spacing-7: 32px;
    --rnf-spacing-8: 42px;
  
    /* Font weights */
    --rnf-font-weight-normal: 400;
    --rnf-font-weight-medium: 500;
    --rnf-font-weight-semibold: 600;
    --rnf-font-weight-bold: 700;
  
    /* Font family */
    --rnf-font-family-sanserif: Inter, -apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif;
  
    /* Border radius */
    --rnf-border-radius-sm: 2px;
    --rnf-border-radius-md: 4px;
    --rnf-border-radius-lg: 8px;
  
    /* Shadows */
    --rnf-shadow-sm: 0px 5px 10px rgba(0, 0, 0, 0.12);
    --rnf-shadow-md: 0px 8px 30px rgba(0, 0, 0, 0.24);
  
    /* Colors */
    --rnf-color-white: #fff;
    --rnf-color-white-a-75: rgba(255, 255, 255, 0.75);
    --rnf-color-black: #000;
    --rnf-color-gray-900: #1a1f36;
    --rnf-color-gray-800: #3c4257;
    --rnf-color-gray-700: #3c4257;
    --rnf-color-gray-600: #515669;
    --rnf-color-gray-500: #697386;
    --rnf-color-gray-400: #9ea0aa;
    --rnf-color-gray-300: #a5acb8;
    --rnf-color-gray-200: #dddee1;
    --rnf-color-gray-100: #e4e8ee;
    --rnf-color-brand-500: #e95744;
    --rnf-color-brand-700: #e4321b;
    --rnf-color-brand-900: #891e10;
  
    /* Component specific colors */
    --rnf-unread-badge-bg-color: #dd514c;
    --rnf-avatar-bg-color: #ef8476;
    --rnf-message-cell-unread-dot-bg-color: #f4ada4;
    --rnf-message-cell-hover-bg-color: #f1f6fc;

    --rnf-button-padding-x: 8px;
    --rnf-button-padding-y: 4px;
    --rnf-button-border-radius: 4px;
    --rnf-button-font-weight: var(--rnf-font-weight-medium);
    --rnf-button-font-size: var(--rnf-font-size-sm);
  
    /* Variant colors */
    /* Primary */
    --rnf-button-primary-bg-color: var(--rnf-color-brand-500);
    --rnf-button-primary-hover-bg-color: var(--rnf-color-brand-700);
    --rnf-button-primary-border-color: transparent;
    --rnf-button-primary-text-color: var(--rnf-color-white);
    /* Secondary */
    --rnf-button-secondary-bg-color: var(--rnf-color-white);
    --rnf-button-secondary-hover-bg-color: #dddee1;
    --rnf-button-secondary-border-color: #dddee1;
    --rnf-button-secondary-text-color: var(--rnf-color-gray-700);

    --rnf-unseen-badge-bg-color: #eb5757;
    --rnf-unseen-badge-size: 16px;
    --rnf-unseed-badge-font-size: 9px;
}

.rnf-notification-icon-button {
  background-color: var(--rnf-notification-icon-button-bg-color);
  border: none;
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  cursor: pointer;
  width: var(--rnf-notification-icon-button-size);
  height: var(--rnf-notification-icon-button-size);
  color: inherit;
}

.rnf-notification-icon-button svg {
  display: block;
  margin: 0 auto;
}

.rnf-notification-icon-button--dark {
  color: #fff;
}

/* Container */
.rnf-notification-feed {
  background-color: var(--rnf-color-white);
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* Dropdown */

.rnf-dropdown {
  font-size: var(--rnf-font-size-md);
  font-weight: var(--rnf-font-weight-medium);
  color: var(--rnf-color-gray-400);
  position: relative;
}

.rnf-dropdown select {
  padding-right: var(--rnf-spacing-3);
  color: currentColor;
  border: none;
  background: transparent;
  appearance: none;
  font-size: var(--rnf-font-size-sm);
  position: relative;
  text-align: right;
  z-index: 2;
}

.rnf-dropdown svg {
  position: absolute;
  top: 50%;
  margin-top: -2px;
  right: 0;
  z-index: 1;
}

/* Mark all as read */

.rnf-mark-all-as-read {
  border: none;
  background: transparent;
  margin-left: auto;
  display: flex;
  align-items: center;
  padding: 0;
  font-size: var(--rnf-font-size-sm);
  color: var(--rnf-color-gray-400);
  cursor: pointer;
}

.rnf-mark-all-as-read:disabled {
  color: var(--rnf-color-gray-200);
  cursor: not-allowed;
}

.rnf-mark-all-as-read svg {
  margin-top: 1px;
  margin-left: var(--rnf-spacing-1);
}

/* Header */

.rnf-notification-feed__header {
  padding: var(--rnf-spacing-3) var(--rnf-spacing-4);
  height: var(--rnf-notification-feed-header-height);
  display: flex;
  align-items: center;
}

.rnf-notification-feed__selector {
  display: flex;
  align-items: center;
}

.rnf-notification-feed__type {
  font-size: var(--rnf-font-size-sm);
  font-weight: var(--rnf-font-weight-medium);
  color: var(--rnf-color-gray-900);
  margin-right: var(--rnf-spacing-2);
}

.rnf-notification-feed__container {
  overflow-y: auto;
  flex: 1;
}

.rnf-notification-feed__spinner-container {
  padding: var(--rnf-spacing-3) var(--rnf-spacing-4);
}

.rnf-notification-feed__spinner-container svg {
  margin: 0 auto;
  display: block;
}

/* Knock branding */

.rnf-notification-feed__knock-branding {
  text-align: center;
}

.rnf-notification-feed__knock-branding a {
  display: block;
  font-size: var(--rnf-font-size-sm);
  color: var(--rnf-color-gray-500);
  padding: 6px;
  border-top: 1px solid var(--rnf-color-gray-100);
}

.rnf-notification-feed__knock-branding a:hover {
  background-color: #f1f6fc;
}

/* Themes */

.rnf-notification-feed--dark {
  background-color: #2e2f34;
}

.rnf-notification-feed--dark .rnf-notification-feed__type {
  color: var(--rnf-color-white-a-75);
}

.rnf-dropdown--dark {
  color: var(--rnf-color-gray-400);
}

.rnf-mark-all-as-read--dark:disabled {
  color: var(--rnf-color-gray-500);
}

.rnf-notification-feed--dark .rnf-notification-feed__knock-branding a {
  color: var(--rnf-color-gray-400);
  border-top-color: rgba(105, 115, 134, 0.65);
}

.rnf-notification-feed--dark .rnf-notification-feed__knock-branding a:hover {
  background-color: #393b40;
}

:root {
  --rnf-notification-feed-popover-max-w: 400px;
  --rnf-notification-feed-popover-min-w: 280px;
  --rnf-notification-feed-popover-height: 400px;
  --rnf-notification-feed-popover-shadow: drop-shadow(
    0px 5px 15px rgba(0, 0, 0, 0.2)
  );
  --rnf-notification-feed-popover-shadow-color: rgba(0, 0, 0, 0.2);
  --rnf-notification-feed-popover-bg-color: #fff;
  --rnf-notification-feed-popover-z-index: 999;
  --rnf-notification-feed-popover-arrow-size: 10px;
  --rnf-notification-feed-popover-border-radius: 4px;
}

.rnf-notification-feed-popover {
  width: 100%;
  max-width: var(--rnf-notification-feed-popover-max-w);
  min-width: var(--rnf-notification-feed-popover-min-w);
  height: var(--rnf-notification-feed-popover-height);
  z-index: var(--rnf-notification-feed-popover-z-index);
}

.rnf-notification-feed-popover__inner {
  overflow: hidden;
  background-color: var(--rnf-notification-feed-popover-bg-color);
  border-radius: var(--rnf-notification-feed-popover-border-radius);
  filter: var(--rnf-notification-feed-popover-shadow);
  height: 100%;
}

.rnf-notification-feed-popover__arrow {
  position: absolute;
  width: var(--rnf-notification-feed-popover-arrow-size);
  height: var(--rnf-notification-feed-popover-arrow-size);
}

.rnf-notification-feed-popover__arrow::after {
  content: " ";
  display: block;
  background-color: var(--rnf-notification-feed-popover-bg-color);
  box-shadow: -1px -1px 1px var(--rnf-notification-feed-popover-shadow-color);
  position: absolute;
  top: -5px;
  left: 0;
  transform: rotate(45deg);
  width: var(--rnf-notification-feed-popover-arrow-size);
  height: var(--rnf-notification-feed-popover-arrow-size);
}

/* Theme */

.rnf-notification-feed-popover--dark {
  --rnf-notification-feed-popover-shadow-color: rgba(0, 0, 0, 0.2);
}

.rnf-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  white-space: nowrap;
  vertical-align: middle;
  width: auto;
  padding: var(--rnf-button-padding-y) var(--rnf-button-padding-x);
  border-radius: var(--rnf-button-border-radius);
  font-size: var(--rnf-button-font-size);
  line-height: var(--rnf-font-size-lg);
  font-weight: var(--rnf-button-font-weight);
  border: 1px solid;
  appearance: none;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

.rnf-button--full-width {
  width: 100%;
}

.rnf-button--primary {
  background-color: var(--rnf-button-primary-bg-color);
  color: var(--rnf-button-primary-text-color);
  border-color: var(--rnf-button-primary-border-color);
}

.rnf-button--primary:hover:not(:disabled),
.rnf-button--primary:active:not(:disabled) {
  background-color: var(--rnf-button-primary-hover-bg-color);
}

.rnf-button:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.rnf-button--secondary {
  background-color: var(--rnf-button-secondary-bg-color);
  color: var(--rnf-button-secondary-text-color);
  border-color: var(--rnf-button-secondary-border-color);
}

.rnf-button--secondary:hover:not(:disabled),
.rnf-button--secondary:active:not(:disabled) {
  background-color: var(--rnf-button-secondary-hover-bg-color);
}

.rnf-button--dark.rnf-button--secondary {
  border-color: #43464c;
  background-color: #43464c;
  color: var(--rnf-color-white-a-75);
}

.rnf-button__button-text-hidden {
  opacity: 0;
}

.rnf-button--dark.rnf-button--secondary:hover:not(:disabled),
.rnf-button--dark.rnf-button--secondary:active:not(:disabled) {
  background-color: var(--rnf-color-gray-600);
}

/* Button spinner */

.rnf-button-spinner {
  display: flex;
  align-items: center;
  font-size: 1rem;
  line-height: "normal";
}

.rnf-button-spinner--without-label {
  position: absolute;
}

.rnf-button-spinner--with-label {
  margin-right: 6px;
}

.rnf-button--primary .rnf-button-spinner circle {
  stroke: white;
}

.rnf-button--secondary .rnf-button-spinner circle {
  stroke: var(--rnf-button-secondary-text-color);
}

.rnf-button--dark.rnf-button--secondary .rnf-button-spinner circle {
  stroke: var(--rnf-color-white-a-75);
}

/* Button group */

.rnf-button-group > .rnf-button + .rnf-button {
  margin-left: 8px;
}

.rnf-unseen-badge {
  background-color: var(--rnf-unseen-badge-bg-color);
  width: var(--rnf-unseen-badge-size);
  height: var(--rnf-unseen-badge-size);
  border-radius: var(--rnf-unseen-badge-size);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  right: 0px;
}

.rnf-unseen-badge__count {
  font-size: var(--rnf-unseed-badge-font-size);
  font-weight: var(--rnf-font-weight-medium);
  color: var(--rnf-color-white);
  margin-top: -1px;
}

/* Themes */

.rnf-unseen-badge--dark {
  --rnf-unseen-badge-bg-color: #ef3434;
}