.apexcharts-tooltip {
    background: #F9F9FD !important;
    opacity: 1 !important;
    color: #000000 !important;
    padding: 0px !important;
    display: none !important;
    font-family: 'Roboto' !important;
    letter-spacing: 0 !important;
  }

  .apexcharts-active {
    display: flex !important;
  }

.apexcharts-tooltip-title {
  background-color: #F9F9FD!important;
  border: none !important;
  margin-bottom: 0px !important;
  margin-top: 5px !important;
  padding-left: 7px !important;
  font-weight: bold;
}

.apexcharts-legend-text {
  font-family: 'Roboto' !important;
    letter-spacing: 0 !important;
}

.apexcharts-tooltip-series-group {
  padding: 2px 8px 2px 8px!important;
  margin-bottom: 5px !important;
}

.apexcharts-yaxis-label {
  letter-spacing: 0 !important;
}

.apexcharts-xaxis-label {
  letter-spacing: -0.06em !important;
}
