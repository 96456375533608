.variable-input-wrapper {
  position: relative;
  width: 100%;
  background-color: #f1f1f1;
  border: 2px solid #f1f1f1;
  padding: 8px;
  border-radius: 8px;
  min-height: 45px;
  height: min-content;
  display: flex;
  align-items: center;
}

.variable-input-wrapper:focus-within {
  border: 2px solid #02231c;
}

.variable-input {
  font-size: 18px;
  width: 100%;
  color: rgba(0, 0, 0, 0.87);
}

.variable-highlight {
  display: inline-block;
  padding: 2px 6px;
  background-color: rgba(146, 227, 169, 0.5);
  border: 1px solid #589F7E;
  border-radius: 4px;
  font-weight: bold;
  color: #023b21;
}

.autocomplete {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  z-index: 1000;
}

.autocomplete-item {
  padding: 8px;
  cursor: pointer;
}

.autocomplete-item:hover {
  background-color: #f0f0f0;
}